<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Vendite</h2>
    <h4 class="text-center pointer" (click)="collapse.toggle()">Filtri<i class="bi ms-2" style="font-size: 16px;"
        [ngClass]="isCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"></i></h4>
    <form class="row m-3 p-3 bg-info rounded" [formGroup]="filters" #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed">
      <div class="col-6 mb-3">
        <label for="dateFrom" class="form-label">Da</label>
        <input type="datetime-local" id="dateFrom" class="form-control" formControlName="dateFrom">
      </div>
      <div class="col-6 mb-3">
        <label for="dateTo" class="form-label">A</label>
        <input type="datetime-local" id="dateTo" class="form-control" formControlName="dateTo">
      </div>
      <div class="col-6 mb-3">
        <label for="service" class="form-label">Servizio</label>
        <app-fh-service-select [reset]="resetServiceValue" [serviceID]="serviceID" [value]="filters.get('service')?.value" (serviceSelected)="updateFHService($event)"></app-fh-service-select>
      </div>
      <div class="col-6 mb-3">
        <label for="email" class="form-label">Cliente</label>
        <input type="text" id="email" class="form-control" formControlName="email">
      </div>
      <div class="col-6 mb-3">
        <label for="province" class="form-label">Provincia</label>
        <app-province-filter [provincia]="filters.get('province')?.value" [includeAll]="true"
          (valueChange)="updateProvincia($event)"></app-province-filter>
      </div>
      <div class="col-6 mb-3">
        <label for="invoiced" class="form-label">Fatturato</label>
        <input type="checkbox" id="invoiced" class="form-check" formControlName="invoiced">
      </div>
      <div class="col-6 mb-3">
        <label for="amount" class="form-label">Importo</label>
        <input type="number" step="1" id="amount" class="form-control" formControlName="amount">
      </div>
      <div class="col-6 mb-3">
        <label for="status" class="form-label">Stato</label>
        <select id="status" class="form-select" formControlName="status">
          <option [value]="null" default hidden>Seleziona uno stato</option>
          <option value="Completed">Completato</option>
          <option value="Failed">Fallito</option>
          <option value="Incomplete">Incompleto</option>
        </select>
      </div>
      <div class="col-6 mb-3">
        <label for="seller" class="form-label">Venditore</label>
        <app-operator-filter [operator]="filters.get('seller')?.value" [includeAll]="true" [includeRecruiter]="true" (valueChange)="updateSeller($event)"></app-operator-filter>
      </div>
      <div class="d-flex col-6 mb-3 align-items-end">
        <button class="col-3 btn btn-primary me-3 text-light"
          (click)="collapse.toggle(); applyFilters()">Applica</button>
          <button class="col-3 btn text-light btn-danger"
          (click)="resetFilters()">Reset</button>
        </div>
    </form>
  </div>
  <table class="table table-hover table-striped">
    <thead>
      <tr>
        <!-- <th></th> -->
        <th scope="col">ID</th>
        <th scope="col">Importo</th>
        <th scope="col">Pagante</th>
        <th scope="col">Telefono</th>
        <th scope="col">Email</th>
        <th scope="col">Servizio</th>
        <th scope="col">Stato</th>
        <th scope="col">Data</th>
        <th scope="col">Venditore</th>
        <th scope="col">Venditore 2</th>
        <!-- <th></th> -->
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let sell of sells">
        <!-- <td>
          <input type="checkbox" [(ngModel)]="sell.selected">
        </td> -->
        <th scope="row">{{sell.id}}</th>
        <td>{{sell.amount / 100 | currency : 'EUR' }}</td>
        <td>{{sell.name}} {{sell.surname}}</td>
        <td><a (click)="copyToClipboard($event)" href="javascript:void(0)">{{sell.phone}}</a></td>
        <td><a target="_blank" [href]="origin+sell.urlRelated">{{!!sell.email && sell.email !== '' ? sell.email : sell.emailPayer}}</a></td>
        <!-- Aggiungere link a elemento collegato in piattaforma -->
        <td>{{sell.serviceName}}</td>
        <td class="text-center"><app-payment-status-badge [status]="sell.status"></app-payment-status-badge></td>
        <td>{{sell.timestamp|date: 'dd MMM yyyy'}} {{sell.timestamp|date: 'HH:mm:ss'}}</td>
        <td>{{sell.seller}}</td>
        <td>{{sell.seller2}}</td>
        <!-- <td><button class="btn btn-primary px-2 py-1" (click)="openPaymentDetail(payment.id)"><i
              class="bi bi-search text-light pointer"></i></button></td> -->
        <td><button class="btn btn-secondary text-light px-2 py-1" (click)="claimPayment(sell.id)">
            Claim</button></td>
      </tr>
      <tr *ngIf="sells.length === 0">
        <td colspan="10" class="text-center">Non sono stati trovati pagamenti.</td>
      </tr>
    </tbody>
  </table>
  <div class="row">
    <p class="col">Sono presenti <b>{{collectionSize}}</b> pagamenti in totale.</p>
    <ngb-pagination class="col d-flex justify-content-end" *ngIf="sells.length > 0" [maxSize]="5" [(page)]="page"
      [pageSize]="pageSize" [collectionSize]="collectionSize" (pageChange)="onPageChange()"></ngb-pagination>
  </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmClaim($event)" [data]="{ type: undefined }"
  [title]="'Conferma rivendicazione'" [message]="'Confermi di aver effettuato la vendita relativa al pagamento ' + selectedPaymentID + '?'">
</app-alert>
