import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, debounceTime, distinctUntilChanged, of, switchMap, takeUntil } from 'rxjs';
import { PaymentService } from '../payment-service/payment.service';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FHService, ModalService, Payment } from 'npx-family-happy-common';
import { environment } from 'src/environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { getURLRelated } from 'src/app/shared/utils.functions';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFilterComponent } from 'src/app/shared/components/custom-select/operator-filter/operator-filter.component';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
  standalone:true,
  imports: [CommonModule, ReactiveFormsModule, NgbTypeaheadModule, OperatorFilterComponent, FHServiceSelectComponent, AlertComponent]
})
export class PaymentDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    amount: new UntypedFormControl(null),
    clientSecret: new UntypedFormControl(null),
    city: new UntypedFormControl(null, [Validators.required]),
    country: new UntypedFormControl(null, [Validators.required]),
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    emailPayer: new UntypedFormControl(null),
    extra: new UntypedFormControl(null),
    fiscalCode: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
    invoiced: new UntypedFormControl(false),
    name: new UntypedFormControl(null, [Validators.required]),
    postalCode: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[0-9]{5}$')]),
    province: new UntypedFormControl(null, [Validators.required, Validators.maxLength(2)]),
    phone: new UntypedFormControl(null),
    street: new UntypedFormControl(null, [Validators.required]),
    surname: new UntypedFormControl(null, [Validators.required]),
    timestamp: new UntypedFormControl(null),
    stripeID: new UntypedFormControl(null),
    subscriptionID: new UntypedFormControl(null),
    seller: new UntypedFormControl(null),
    seller2: new UntypedFormControl(null),
    service: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    status: new UntypedFormControl(null),
    provinceCode: new UntypedFormControl(null),
    urlRelated: new UntypedFormControl(null),
    promoCode: new UntypedFormControl(null),
    couponCode: new UntypedFormControl(null),
    checkoutURL: new UntypedFormControl(null)
  })
  selectedPayment: Payment | undefined;

  showDeleteConfirm = false;
  isNew = false;
  formError = false;

  private unsubscribe = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private modalService: ModalService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe((param) => {
      const id = param.get('id') ?? -1;
      if (id === 'create') {
        this.isNew = true;
      } else {
        if (id === -1) {
          this.goBackToPayments();
        } else {
          this.paymentService.getPaymentByID(+id).subscribe((payment: Payment) => {
            this.selectedPayment = payment;
            this.initForm(payment);
          })
        }
      }
    })
    //this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => console.log(value, this.form));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('street') as HTMLInputElement;
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      /* const mapOptions = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      }; */

      const autocomplete = new google.maps.places.Autocomplete(inputAddress);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let addressParts: Array<string> = [];
        let formAddress = this.form.get('street');
        let formCity = this.form.get('city');
        let formCap = this.form.get('postalCode');
        let street = '';
        let streetNumber = '';

        place.address_components?.forEach((component: any) => {
          component.types.forEach((type: any) => {
            addressParts.push(type);
          });

          if (component.types.includes('route')) {
            street = component.long_name;
          }

          if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
          }

          if (component.types.includes('administrative_area_level_2')) {
            this.form.get('province')?.setValue(component.short_name);
          }
          // Compile other address fields according to place object
          if (component.types.includes('locality')) {
            formCity?.setValue(component.long_name);
          } else if (component.types.includes('administrative_area_level_3')) {
            formCity?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            formCap?.setValue(component.long_name);
          }
          if (component.types.includes('country')) {
            this.form.get('country')?.setValue(component.long_name);
          }
        });
        const address = `${street}, ${streetNumber}`;

        formAddress?.setValue(address);
      })
    });
  }

  goBackToPayments() {
    this.router.navigate(['/payments']);
  }

  deletePayment() {
    this.showDeleteConfirm = true;
  }

  confirmDelete(answer: boolean) {
    if (answer && this.selectedPayment && this.selectedPayment.id > -1) {
      this.paymentService.deletePaymentById(this.selectedPayment.id).subscribe(() => {
        this.modalService.showSuccessMessage('Pagamento eliminato');
        this.goBackToPayments();
      });
    }
    this.showDeleteConfirm = false;
  }

  emitInvoice() {
    if (this.form.valid) {
      const formValue = this.form.value;
      const payment: Payment = {
        ...formValue
      }
      this.paymentService.emitInvoice(payment).subscribe(() => {
        this.modalService.showSuccessMessage('Fattura emessa')
        this.paymentService.getPaymentByID(this.form.value.id).subscribe((payment: Payment) => {
          this.selectedPayment = payment;
          this.initForm(payment);
        })
      });
    }
  }

  savePayment() {
    if (this.form.valid) {
      const formValue = this.form.value;
      const payment: Payment = {
        ...formValue,
        amount: formValue.amount * 100,
        timestamp: new Date(formValue.timestamp).toISOString() ?? new Date()
      }
      this.paymentService.savePayment(payment).subscribe(() => this.modalService.showSuccessMessage('Pagamento aggiornato'));
    } else {
      const Error = 'Attenzione! Alcuni campi obbligatori sono mancanti o non compilati correttamente'
      this.modalService.showErrorMessage(Error)
    }
  }

  copyStripeID() {
    if (this.selectedPayment) {
      navigator.clipboard.writeText(this.selectedPayment.stripeID);
      this.modalService.showSuccessMessage('StripeID copiato con successo!');
    }
  }

  copySubscriptionID() {
    if (this.selectedPayment) {
      navigator.clipboard.writeText(this.selectedPayment.subscriptionID);
      this.modalService.showSuccessMessage('SubscriptionID copiato con successo!');
    }
  }

  emailSearch = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((email) => {
        if (this.form.get('email')?.valid) {
          return this.paymentService.getPossiblePayers(email);
        } else {
          return of([]);
        }
      }),
    );
  }

  formatter = (x: string) => {
    return x;
  }

  onEmailSelected(event: any) {
    const payer = event.item;
    event.preventDefault();

    this.form.setValue({
      ...this.form.value,
      email: payer.email,
      name: payer.name,
      surname: payer.surname,
      fiscalCode: payer.fiscalcode,
      street: payer.address ? payer.address.split(',').slice(0, 2).join(',') : '',
      city: payer.city,
      province: payer.provincia,
      country: 'Italia',
      postalCode: payer.cap,
      provinceCode: payer.provincia,
      phone: payer.phone,
      urlRelated: getURLRelated(payer.type, payer.docRef)
    })
    this.form.markAllAsTouched();
  }

  updateSeller(newValue: string, field: string) {
    this.form.get(field)?.setValue(newValue);
    this.form.markAllAsTouched();
    this.form.get(field)?.markAsDirty();
  }

  updateFHService(service: FHService) {
    this.form.get('service')?.setValue(service);
    this.form.markAllAsTouched();
    this.form.get('service')?.markAsDirty();
  }

  private initForm(payment: Payment) {
    this.form.setValue({
      ...payment,
      amount: payment.amount / 100,
      service: payment.service,
      timestamp: this.initFormDate(payment.timestamp)
    })
  }

  private initFormDate(date: Date) {
    return this.datePipe.transform(date, 'YYYY-MM-dd HH:mm');
  }

  openNewPageWithUrl() {
    const url = `${location.protocol}//${location.host}${this.form.value.urlRelated}`;
    window.open(url, "_blank");
  }
}

