export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCBkSMv7uwknRwiCv6CdY7IbMEM74BqmAo",
    authDomain: "familyhappy-babysitter.firebaseapp.com",
    databaseURL: "https://familyhappy-babysitter-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "familyhappy-babysitter",
    storageBucket: "familyhappy-babysitter.appspot.com",
    messagingSenderId: "185381709976",
    appId: "1:185381709976:web:0105b48fdb472e417d88ed",
    measurementId: "G-CFPQN9NS6H"
  },
  mailServiceURL: 'https://mailapi.familyhappy.xyz',
  babysitterServiceURL: 'https://babysitterapi.familyhappy.xyz/v1/babysitters',
  caregiverScoreURL: 'https://babysitterapi.familyhappy.xyz/v1/caregiver-scoring',
  caregiverEvaluationURL: 'https://babysitterapi.familyhappy.xyz/v1/caregiver-evaluation-history',
  petsitterServiceURL: 'https://babysitterapi.familyhappy.xyz/v1/petsitters',
  nurseServiceURL: 'https://babysitterapi.familyhappy.xyz/v1/nurses',
  colfServiceURL:'https://babysitterapi.familyhappy.xyz/v1/colfs',
  requestServiceURL: 'https://request.familyhappy.xyz/v1/requests',
  requestPetsitterServiceURL: 'https://request.familyhappy.xyz/v1/petsitter-request',
  requestNurseServiceURL: 'https://request.familyhappy.xyz/v1/nurses-request',
  requestColfServiceURL:'https://request.familyhappy.xyz/v1/colfs-request',
  bulletinBoardServiceURL: 'https://request.familyhappy.xyz/v1/annunci',
  paymentsAPI:'https://request.familyhappy.xyz/v1/payments',
  productsAPI:'https://request.familyhappy.xyz/v1/products',
  servicesAPI:'https://request.familyhappy.xyz/v1/services',
  emailTemplateAPI:'https://request.familyhappy.xyz/v1/mail-templates',
  priceAPI:'https://request.familyhappy.xyz/v1/prices',
  caregiverSelectionAPI:'https://request.familyhappy.xyz/v1/caregiver-selection',
  formBaseURL: 'https://babysitter-familyhappy.firebaseapp.com',
  cloudFunctionsBaseURL: 'https://europe-west3-familyhappy-babysitter.cloudfunctions.net',
  googleMapsAPIKey: 'AIzaSyA_i3emNZmlRZW41KS4Fn8DiFQUovb0vQU',
  annunciKey: 'sk_live_annunci_LwRC1JOKJgVOmGcsExHESsMFtkcbrZIfe7tzGxvEhaU1YXmRER6dKJJsCe8Jj4FgXatt9T8UNiA4OnW4FgaxWe9uOXFD113wDI6p1CAMbZ9O8DyEY5GhyYakijCUzEJrytO733TWEErzrcuiSqzt2LspF7jhIofXAk08V0gEytyeZJGweKJ0FppxM3VR6puvEtWlzRdbgL1AJNfy4hCSubzBeYvowmr22T8R9TwjqTCfIFAMsIQqGHSXLGGoGoFvQ41Ev9q6yEJ72OSQJmCv38GUYPuChyeRIAcKahGxrllKYPh1Anr7zlF0aEvIuJfxfPGzUkRyLHBP3UTkYig7cUy8jCkMA9mVwvm4LKNdHyWggBVDO11hhs620LKChLi2GteuNVLQ7bA8tfvbgWkh5IThb44XvlAyybrttGWgI96wUr6tvhPrJ0Uaw7AYue37NsdNrojRAEOabRDj5kxhMIRnPsktUxIutRNdeEu4oDqaXMyp',
  bachecaURL: 'https://babysitter-familyhappy.web.app/',
  scheduledAppointmentsAPI:'https://mailapi.familyhappy.xyz/v1/scheduled-appointments',
  loadBalanceAPI:'https://request.familyhappy.xyz/v1/request-load-balance/'
};
